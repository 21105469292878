/* eslint-disable no-warning-comments */

import './src/styles/globals.scss'

import * as React from 'react'

import {PageTransition, PageTransitionWrapper, TRANSITION_EXIT} from '@/components/PageTransition'

// import {ScrollSmootherContext, ScrollSmootherWrapper} from '@/components/ScrollSmoother'

// export const wrapRootElement = ({element}) => {
//   return <ScrollSmootherContext>{element}</ScrollSmootherContext>
// }

export const wrapPageElement = ({element, props}) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  React.useEffect(() => {
    const axeptioScript = document.getElementById('axeptio-script')

    if (!axeptioScript) {
      const firstScriptTag = document.getElementsByTagName('script')[0]

      window.axeptioSettings = {
        clientId: process.env.GATSBY_AXEPTIO_CLIENT_ID,
        cookiesVersion: process.env.GATSBY_AXEPTIO_COOKIES_VERSION,
      }

      void 0 === window._axcb && (window._axcb = [])
      window._axcb.push(function (axeptio) {
        axeptio.on('cookies:complete', function (choices) {
          if (!!choices.matomo) {
            var _mtm = (window._mtm = window._mtm || [])
            window._mtm.push(['enableDebugMode'])
            _mtm.push({'mtm.startTime': new Date().getTime(), event: 'mtm.Start'})
            ;(function () {
              var d = document,
                g = d.createElement('script'),
                s = d.getElementsByTagName('script')[0]
              g.async = true
              g.src = process.env.GATSBY_MATOMO_URL
              s.parentNode.insertBefore(g, s)
            })()
          }
        })
      })

      const axeptioScriptTag = document.createElement('script')
      axeptioScriptTag.id = 'axeptio-script'
      axeptioScriptTag.src = '//static.axept.io/sdk.js'
      axeptioScriptTag.async = true

      firstScriptTag.parentNode.insertBefore(axeptioScriptTag, firstScriptTag)
    }
  }, [])

  return (
    <>
      <PageTransition {...props} />
      <PageTransitionWrapper initial={false}>{element}</PageTransitionWrapper>
    </>
  )
}

export const onPreRouteUpdate = () => {
  // Prevent ScrollSmoother scrolling to top
  if (window.__smoother) window.__smoother.paused(true)
}

export const shouldUpdateScroll = ({
  routerProps: {location},
  prevRouterProps,
  getSavedScrollPosition,
}) => {
  const duration = TRANSITION_EXIT * 1000

  if (window.__smoother) {
    // Anchor
    if (
      prevRouterProps &&
      location.pathname === prevRouterProps.location.pathname &&
      location.hash
    ) {
      window.__smoother.paused(false).scrollTo(location.hash, true, 'top top')
    }
    // Same URL
    else if (prevRouterProps && location.pathname === prevRouterProps.location.pathname) {
      window.__smoother.paused(false)
      window.__smoother.scrollTo(0, true)
    }
    // Link
    else if (location.action === 'PUSH') {
      window.setTimeout(() => window.__smoother.scrollTop(0), duration)
    }
    // Browser's forwards or back button
    else {
      const savedPosition = getSavedScrollPosition(location) || [0, 0]
      window.__smoother.paused(false)
      window.setTimeout(
        () => window.__smoother.scrollTo(savedPosition[1], true, 'top top'),
        duration //* 2
      )
    }
  }

  return false
}
